<script setup  lang="ts">
import SectionLabel from '@voix/components/chrome/SectionLabel.vue'
import { ref, useSlots } from 'vue'
import type { Ref } from 'vue'

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  border: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  focusOnShow: {
    type: Boolean,
    default: false,
  },
})

const slots = useSlots()

const modelValue = defineModel()

const input: Ref<HTMLInputElement | null> = ref(null)

if (props.focusOnShow)
  input.value?.focus()
</script>

<template>
  <div
    :class="{
      'opacity-50': props.disabled,
    }"
  >
    <SectionLabel v-if="props.label">
      {{ props.label }}
    </SectionLabel>
    <div
      class="group relative flex items-center pt-1 text-gray-400"
    >
      <!-- Slot for SVG Icons -->
      <div class="translate-x-3">
        <slot />
      </div>

      <input
        :id="props.id"
        ref="input"
        v-model="modelValue"
        :type="props.type"
        class="pt-2 text-sm pb-1.5 w-full text-gray-500 bg-transparent border-gray-200 rounded-sm placeholder-gray-300  "
        :class="{ '-ml-3 pl-7': slots.default, 'pl-2': !slots.default }"
        :placeholder="placeholder"
        :disabled="props.disabled"
      >
    </div>
  </div>
</template>
